import React, { Component } from 'react';
import Image from 'react-image-webp';

class Gallery extends Component {
  render() {
    return (
      <div className="gallery--container">
          <div className="tc">
            <h1 className="tc bb b--white-60 f2 dib fw4">Gallery</h1>
          </div>

          <article className="cf">
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={process.env.PUBLIC_URL + '/images/DSC_4956.jpg'}
                webp={process.env.PUBLIC_URL + '/images/DSC_4956.webp'}
              />
            </div>
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={process.env.PUBLIC_URL + '/images/DSC_5145.jpg'}
                webp={process.env.PUBLIC_URL + '/images/DSC_5145.webp'}
              />
            </div>
          </article>

          <article className="cf">
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={process.env.PUBLIC_URL + '/images/DSC_5137.jpg'}
                webp={process.env.PUBLIC_URL + '/images/DSC_5137.webp'}
              />
            </div>
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={process.env.PUBLIC_URL + '/images/DSC_5149.jpg'}
                webp={process.env.PUBLIC_URL + '/images/DSC_5149.webp'}
              />
            </div>
          </article>

          <article className="cf">
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={process.env.PUBLIC_URL + '/images/DSC_5168.jpg'}
                webp={process.env.PUBLIC_URL + '/images/DSC_5168.webp'}
              />
            </div>
            <div className="fl w-100 w-50-ns tc pa3">

            </div>
          </article>
      </div>
    );
  }
}

export default Gallery;
