import React, { Component } from 'react';
import Image from 'react-image-webp';
import ResponsiveVideo from './ResponsiveVideo';

class Home extends Component {
  state = {
    quotes: [
      {
        'text':'Unique. I was totally entertained!',
        'person':'Dick Clark'
      },
      {
        'text':'I watched from one foot away... AMAZING!',
        'person':'Chris Hansen'
      },
    ]
  }

  render() {
    const { quotes } = this.state;

    var quote = quotes[Math.floor(Math.random() * quotes.length)];

    return (
      <>
        <div className="home--container">
          <div className="hero-image">
            <Image
              src={require('../static/images/upper-head.png')}
              webp={require('../static/images/upper-head.webp')}
            />
            <Image
              className="upper-hand"
              src={require('../static/images/upper-hand.png')}
              webp={require('../static/images/upper-hand.webp')}
            />
          </div>
          <div className="home">
            <div className="home--inner">
              <h1 className="f5 f4-l fw2 white-90 mt0 mb0 lh-title tc">{quote.text}</h1>
              <h2 className="fw1 f5 white-80 mt0 pt3 mb0 tc tr-l mw6-l center w-100">- {quote.person}</h2>
            </div>
          </div>
          <div className="hero-image lower">
            <Image
              src={require('../static/images/lower-hand.png')}
              webp={require('../static/images/lower-hand.webp')}
            />
          </div>

          <article className="cf home--article">
            <div className="fl w-100 w-33-ns tc pa3-ns">
              <ResponsiveVideo poster={process.env.PUBLIC_URL + '/images/maxresdefault.jpg'} sourceMp4={process.env.PUBLIC_URL + '/videos/Magic Guise-fdm-7z2unJI.mp4'} sourceWebm={process.env.PUBLIC_URL + '/videos/Magic Guise-fdm-7z2unJI.webm'}/>
            </div>
            <div className="fl w-100 w-33-ns tc pa3-ns">
              <ResponsiveVideo poster={process.env.PUBLIC_URL + '/images/poster.jpg'} sourceMp4={process.env.PUBLIC_URL + '/videos/MagicJustforLaughs_GENERIC.mp4'} sourceWebm={process.env.PUBLIC_URL + '/videos/MagicJustforLaughs_GENERIC.webm'}/>
            </div>
            <div className="fl w-100 w-33-ns tc pa3-ns">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/0At1SG6rodg?si=1j5ejrD3eDuniFOA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

          </article>
        </div>
      </>
    );
  }
}

export default Home;
