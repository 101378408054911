import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Press from './Press';
import Gallery from './Gallery';
import Header from './Header';
import Footer from './Footer';

export default () => (
  <div className="container">
    <Header />
    <div className="main">
      <Switch>
        <Route extact path="/gallery.html" component={Gallery} />
        <Route extact path="/press.html" component={Press} />
        <Route extact path="/" component={Home} />
      </Switch>
    </div>
    <Footer />
  </div>
);
