import React, { Component } from 'react';
import Image from 'react-image-webp';

class Press extends Component {
  render() {
    return (
      <div className="press--container">
          <div className="tc">
            <h1 className="tc bb b--white-60 f2 dib fw4">Press</h1>
          </div>

          <article className="f3-ns">
            Andy has spent over 50 years in the entertainment business. As a musician he toured with legends like Chuck Berry, Bo Diddley, Odetta and others. He can be heard on award winning recordings with Pete Seeger, John Prine and Steve Goodman. As a Magician/Comic he has been "Keeping Them Amazed" since his 1979 debut at "Dangerfields" in New York City... P.S. he also played a year of basketball against Meadowlark Lemon and Curley Neal's Harlem Globetrotters.
          </article>

          <article className="cf">
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={require('../static/images/a0.png')}
                webp={require('../static/images/a0.webp')}
              />
            </div>
            <div className="fl w-100 w-50-ns tc pa3 f4 f2-ns">
              <p>"Unique... I Was totally<br/>Entertained"<br/>Dick Clark</p>
              <p>"The applause never ended"<br/>Ken Hamway:<br/>The Providence Jornal Bullentin</p>
              <p>"Andy performed a mentalism routine involving "Dumb Celebrity Quotes"<br/>that had the audience roaring with laughter."<br/>Faren Fagen: The Palm Beach Post</p>
            </div>
          </article>

          <article className="cf">
            <div className="fl w-100 w-50-ns tc pa3 f4 f2-ns">
              <p>"Every seat was full.<br/>Every "Bit" was either funny or amazing!<br/>If Andy gets the audience he deserves...<br/>They will need a bigger theater."<br/><br/>Keith Blackmore: The Royal Gazette.</p>
            </div>
            <div className="fl w-100 w-50-ns tc pa3">
              <Image
                src={process.env.PUBLIC_URL + '/images/DSC_5137.jpg'}
                webp={process.env.PUBLIC_URL + '/images/DSC_5137.webp'}
              />
            </div>
          </article>
      </div>
    );
  }
}

export default Press;
