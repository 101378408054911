import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        <div className="copyright">
					Copyright &copy; MagicGuise.com {(new Date().getFullYear())}
				</div>
      </footer>
    );
  }
}

export default Footer;
