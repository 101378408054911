import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <header id="header">
          <nav className="db dt-ns w-100 center">
            <div className="db v-mid pa3 dtc-ns tc tl-ns">
              <Link to="/" className="db dib-ns mb3 mb0-ns pr3-ns mr3-ns b--white-90 border-box br-ns">
                MagicGuise.com
              </Link>
              <Link to="tel:+1561-284-5558">
                561-284-5558
              </Link>
            </div>
            <div className="db dtc-ns v-mid tc tr-ns pa3 menu">
              <Link className="f3 f6-ns white-60 hover-white" to="/press.html" >Press</Link>
            </div>
          </nav>
      </header>
    );
  }
}

export default Header;
